<template>
    <v-ga-product v-slot="{ select }"
        :product="product">
        <div class="relative">
            <!-- Wishlist -->
            <button v-if="type === 'SimpleProduct'"
                type="button"
                class="group absolute right-0 top-0 z-20 outline-none"
                :title="tg('aria.add-to-wishlist')"
                @click.stop="addToWishlist">
                <v-icon
                    icon="heart"
                    class="m-xs size-[18px] group-hover:fill-red-500
                        group-focus:fill-red-500 group-active:fill-red-600
                        md:size-md" />
            </button>

            <nuxt-link :to="url"
                class="group"
                @click="onClick($event, select)">
                <div class="relative flex aspect-square items-center justify-center rounded bg-white"
                    :class="{
                        'p-sm md:p-md lg:p-lg': imageType === 'cutout',
                        'overflow-hidden': imageType === 'model',
                    }">
                    <v-sale-tag v-if="promotion"
                        class="absolute left-xs top-xs z-10"
                        :promotion="promotion"
                        theme="black"
                        :price="price"
                        :original-price="originalPrice"
                        :is-search="false" />

                    <div class="absolute inset-0 rounded"
                        :class="{
                            'bg-gray-500/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100':
                                imageType === 'model',
                            'bg-gray-500/5 group-hover:bg-gray-500/10': imageType === 'cutout',
                        }"></div>

                    <!-- Primary image -->
                    <nuxt-picture v-if="image"
                        :key="image.url"
                        :src="image.url"
                        :alt="product.title || ''"
                        loading="lazy"
                        :width="image.width"
                        :height="image.height"
                        :img-attrs="{
                            class: `w-full h-full ${imageType === 'cutout'
                                ? 'object-contain pt-[2rem] pb-[1.75rem]' : 'object-cover'}`,
                        }"
                        class="aspect-square"
                        :class="{
                            'transition-opacity duration-300 ease-in-out group-hover:opacity-0': hasHoverImage,
                        }"
                        :sizes="imageType === 'cutout'
                            ? 'xs:50vw sm:252px md:232px lg:180px xl:244px 2xl:308px'
                            : 'xs:50vw sm:296px md:376px lg:244px xl:308px 2xl:372px'
                        " />

                    <!-- Secondary hover image -->
                    <nuxt-picture v-if="hasHoverImage"
                        :key="hoverImage.url"
                        :src="hoverImage.url"
                        :alt="product.title || ''"
                        :width="hoverImage.width"
                        :height="hoverImage.height"
                        :img-attrs="{
                            class: `w-full h-full ${hoverImageType === 'cutout'
                                ? 'object-contain pt-[2rem] pb-[1.75rem]' : 'object-cover'}`,
                        }"
                        class="absolute inset-0 p-md opacity-0 mix-blend-multiply transition-opacity
                            duration-300 ease-in-out group-hover:opacity-100"
                        :sizes="(hoverImageType === 'cutout' && isSearch
                            ? 'xs:50vw sm:252px md:132px lg:180px xl:244px 2xl:308px'
                            : 'xs:50vw sm:296px md:376px lg:244px xl:308px 2xl:372px')
                            || hoverImageType !== 'cutout' && isSearch
                            ? 'xs:50vw sm:296px md:376px lg:244px xl:308px 2xl:372px'
                            : 'xs:50vw sm:252px md:132px lg:180px xl:244px 2xl:308px'
                        " />

                    <!-- Tuotekortin tagit -->
                    <div class="absolute bottom-xs left-0 flex flex-row
                        items-end gap-x-2 gap-y-1
                        overflow-auto px-xs scrollbar-none md:gap-y-2">
                        <template v-for="badge in textProductBadges"
                            :key="badge.id">
                            <v-product-card-badge v-if="badge.listingTitle && badge.listingTitle.length > 0"
                                :badge="badge">
                                {{ badge.listingTitle }}
                            </v-product-card-badge>
                        </template>
                    </div>
                </div>

                <div class="p-2">
                    <div class="flex flex-row justify-between group-hover:underline">
                        <v-subtitle
                            class="truncate font-bold leading-4 tracking-xl md:text-base md:leading-5"
                            size="sm"
                        >
                            {{ title }}
                        </v-subtitle>
                        <!-- Tuotteen värit -->
                        <div v-if="product.swatch.type === 'COLORS'"
                            class="flex justify-end gap-1">
                            <v-color-option v-for="color in product.swatch.colors"
                                :key="color"
                                :color="color" />
                        </div>

                        <div v-else-if="product.swatch.type !== 'MISSING_COLORS'"
                            class="flex justify-end gap-1">
                            <v-color-option :color="product.swatch.type" />
                        </div>
                    </div>

                    <p class="truncate font-sans text-sm text-gray-500 md:text-base">
                        {{ specification }}
                    </p>

                    <div v-if="originalPrice"
                        class="flex items-baseline space-x-2">
                        <template v-if="clubPrice">
                            <p class="font-prenton-cond text-lg leading-5 tracking-xl text-red-500
                                md:text-3xl md:leading-9">
                                <span v-if="type === 'ConfigurableProduct'"
                                    class="text-base">
                                    {{ t('product-part-payment-prefix') }}
                                </span>

                                {{ formatCurrency(clubPrice) }}
                            </p>

                            <p v-if="clubPrice !== originalPrice"
                                class="font-sans text-xs tracking-xl text-gray-500 line-through
                                    md:text-sm">
                                {{ formatCurrency(originalPrice) }}
                            </p>
                        </template>

                        <template v-else>
                            <p class="font-prenton-cond text-lg leading-5 tracking-xl text-red-500
                                md:text-3xl md:leading-9">
                                <span v-if="type === 'ConfigurableProduct'"
                                    class="text-base">
                                    {{ t('product-part-payment-prefix') }}
                                </span>

                                {{ formatCurrency(price) }}
                            </p>

                            <p v-if="price !== originalPrice"
                                class="font-sans text-xs tracking-xl text-gray-500 line-through
                                    md:text-sm">
                                {{ formatCurrency(originalPrice) }}
                            </p>
                        </template>

                        <div class="flex flex-1 justify-end space-x-1 self-center md:space-x-2">
                            <v-product-card-badge v-for="badge in iconProductBadges"
                                :key="badge.id"
                                :badge="badge"
                                icon-only>
                            </v-product-card-badge>
                        </div>
                    </div>

                    <div v-if="deliveryEstimateDays > 0">
                        <p class="mt-2 font-sans text-sm text-[#28a22b] md:text-base">
                            {{ deliveryEstimate }}
                        </p>
                    </div>
                </div>
            </nuxt-link>
        </div>
    </v-ga-product>
</template>

<script lang="ts" setup>
import type { Product } from '@apptus/esales-api';
import { capitalCase } from 'case-anything';
import { useGtm } from '@gtm-support/vue-gtm';
import { useToast } from '~~/store/toast';
import type { PromotionData, ElevateProductBadge } from '~~/types';

const properties = defineProps<{
    product: Product
    isSearch?: boolean
}>();

const wishlist = useCart(true);
const toast = useToast();
const { t } = useI18n();
const { t: tg } = useI18n({ useScope: 'global' });
const { $eventbus } = useNuxtApp();
const { notifyClick } = useElevate();
const gtm = useGtm();

const { getStringValues } = useElevateProduct(properties.product);
const { format: formatCurrency } = useCurrency();

const image = computed(() => properties.product.imageInfo.images[0]?.sources[0]);
const imageType = computed(() => (
    properties.product.imageInfo.images[0]?.custom?.type === 'model' ? 'model' : 'cutout'
));

const hoverImage = computed(() => properties.product.imageInfo.images[1]?.sources[0] ?? undefined);
const hoverImageType = computed(() => (
    properties.product.imageInfo.images[1]?.custom?.type === 'model' ? 'model' : 'cutout'
));

const hasHoverImage = computed(() => hoverImage.value !== undefined && imageType.value === 'model');

const title = computed(() => (
    capitalCase(getStringValues('title')[0] || properties.product.title.split(' ')[0])
));
const specification = computed(() => (
    (getStringValues('specification')[0] || properties.product.title.split(' ').slice(1).join(' ')).toLocaleLowerCase()
));
const price = computed(() => properties.product.sellingPrice.min);
const originalPrice = computed(() => properties.product.listPrice.min);
const url = computed(() => properties.product.link);

const type = computed(() => getStringValues('type')[0]);
const productId = computed(() => getStringValues('id')[0]);
const sku = computed(() => getStringValues('sku')[0]);
const productBadges = computed<ElevateProductBadge[]>(() => {
    const value = getStringValues('productBadges')[0];

    return value ? JSON.parse(value) : [];
});
const iconProductBadges = computed<ElevateProductBadge[]>(() => productBadges.value.filter((badge) => !!badge.icon));
const textProductBadges = computed<ElevateProductBadge[]>(() => productBadges.value.filter((badge) => !badge.icon));

const promotion = computed<PromotionData | undefined>(() => {
    const value = getStringValues('promotion')[0];

    return value ? JSON.parse(value) : undefined;
});

const deliveryEstimateDays = computed(() => (properties.product.custom?.delivery_estimate_days
    ? Number.parseInt((properties.product.custom?.delivery_estimate_days[0].id), 10)
    : 0));

const clubPrice = computed<number | undefined>(() => {
    if (properties.product.custom?.clubPrice) {
        return Number(properties.product.custom?.clubPrice[0].id);
    }

    return properties.product.variants
        .map((variant) => variant.custom?.clubPrice?.[0]?.id)
        .filter((value): value is string => !!value)
        .map(Number)
        .sort((a, b) => a - b)
        .at(0);
});

async function onClick(event: Event, callback: (event: Event) => void) {
    await notifyClick(properties.product.ticket);

    callback(event);
}

const gaProduct = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    item_id: sku.value,
    item_name: properties.product.title,
    item_unique_id: productId.value,
    ...properties.product.brand ? { item_brand: properties.product.brand } : {},
    price: properties.product.sellingPrice.min,
    quantity: 1,
    /* eslint-enable @typescript-eslint/naming-convention */
}));

async function addToWishlist() {
    if (!productId.value) {
        return;
    }

    gtm?.trackEvent({
        /* eslint-disable @typescript-eslint/naming-convention */
        event: 'add_to_wishlist',
        _clear: true,
        ecommerce: {
            currency: 'EUR',
            value: gaProduct.value.price,
            items: [gaProduct.value],
        },
        /* eslint-enable @typescript-eslint/naming-convention */
    });

    try {
        await wishlist.addProduct(productId.value);

        $eventbus.emit('product-added-to-wishlist', {
            productId: productId.value,
        });
    } catch {
        toast.add('warning', `${tg('toast.wishlist-warning')}`);
    }
}

const deliveryEstimate = computed<string | undefined>(() => {
    if (deliveryEstimateDays.value <= 1) {
        return t('delivery-feed.less-than-one');
    }

    if (deliveryEstimateDays.value <= 7) { return `${tg('delivery-feed.two-to-six')}`; }
    if (deliveryEstimateDays.value <= 14) { return `${tg('delivery-feed.seven-to-fourteen')}`; }
    if (deliveryEstimateDays.value <= 22) { return `${tg('delivery-feed.fifteen-to-twenty-two')}`; }
    if (deliveryEstimateDays.value <= 42) { return `${tg('delivery-feed.twenty-three-to-forty-two')}`; }
    if (deliveryEstimateDays.value <= 62) { return `${tg('delivery-feed.forty-three-to-sixty-two')}`; }
    if (deliveryEstimateDays.value > 62) { return `${tg('delivery-feed.more-than-sixty-two')}`; }

    return `${tg('delivery.over-4-weeks')}`;
});
</script>

<i18n lang="json">
{
    "et": {
        "quick-delivery": "Kiire tarne",
        "product-part-payment": "Maksa osade kaupa ",
        "product-part-payment-prefix": "al.",
        "product-part-payment-suffix": "/kuu"
    },
    "fi": {
        "quick-delivery": "Nopea toimitus",
        "product-part-payment": "Tai maksa erissä ",
        "product-part-payment-prefix": "alk.",
        "product-part-payment-suffix": "/kk"
    }
}
</i18n>
