import mitt, { type EventType } from 'mitt';

interface Events extends Record<EventType, unknown> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'product-added-to-cart': {
        productId: string
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'product-added-to-wishlist': {
        productId: string
    }
}

export default defineNuxtPlugin(() => {
    const emitter = mitt<Events>();

    return {
        provide: {
            eventbus: emitter,
        },
    };
});
