import type { ISbStoriesParams } from 'storyblok-js-client';

export function useStoryblokGetAll(name: string, path: string, parameters?: ISbStoriesParams) {
    const api = useStoryblokApi();

    return useAsyncData(
        name,
        () => api.getAll(path, {
            ...parameters,
            version: useRuntimeConfig().public.storyblokVersion === 'published' ? 'published' : 'draft',
        }),
    );
}
