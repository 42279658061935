<template>
    <component :is="iconComponent"
        :key="`icon-${icon}`"
        class="inline-block size-[1em] align-[-.125em]"
        role="img"
        aria-hidden="true"
        focusable="false" />
</template>

<script lang="ts" setup>
import type { Icon } from '~~/types';

const properties = defineProps<{
    icon?: Icon
}>();

const iconComponent = defineAsyncComponent(() => import(`../assets/icons/${properties.icon}.svg?component`));
</script>
