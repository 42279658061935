interface StoryblokLink {
    /* eslint-disable @typescript-eslint/naming-convention */
    id: number
    slug: string
    name: string
    is_folder: boolean
    parent_id: number
    published: boolean
    position: number
    uuid: string
    is_startpage: boolean
    real_path: string
    /* eslint-enable @typescript-eslint/naming-convention */
}

export function useStoryblokLinks(): ComputedRef<StoryblokLink[]> {
    const { data } = useStoryblokGetAll('storyblok-links', 'cdn/links');

    return computed(() => data.value || []);
}
