/* eslint-disable unicorn/consistent-function-scoping */
import { decodeJwt } from 'jose';

/* eslint-disable @typescript-eslint/naming-convention */
interface TokenResponse {
    access_token?: string
    refresh_token?: string
    id_token?: string
    scope?: string
    expires_in?: number
    token_type?: string
    error?: string
}
/* eslint-enable @typescript-eslint/naming-convention */

export function useAuth() {
    const config = useRuntimeConfig();

    const isJwtExpired = (token: string) => {
        const { exp } = decodeJwt(token);
        const currentTime = Date.now() / 1000;

        return !!exp && currentTime > exp;
    };

    const logout = async (): Promise<void> => {
        useStatefulCookie('auth0.token').value = null;
        useStatefulCookie('auth0.refresh_token').value = null;

        const url = new URL(`https://${config.public.auth0Domain}/v2/logout`);

        const parameters = new URLSearchParams({
            /* eslint-disable @typescript-eslint/naming-convention */
            client_id: config.public.auth0ClientId,
            /* eslint-enable @typescript-eslint/naming-convention */
        });

        url.search = parameters.toString();

        await navigateTo(url.toString(), { external: true });
    };

    const isAuthenticated = (): boolean => {
        const accessTokenCookie = useStatefulCookie('auth0.token');
        const refreshTokenCookie = useStatefulCookie('auth0.refresh_token');

        if (!accessTokenCookie.value || !refreshTokenCookie.value) {
            return false;
        }

        return !isJwtExpired(accessTokenCookie.value);
    };

    const login = async (redirectUri?: string, redirect = true): Promise<string | undefined> => {
        useStatefulCookie('auth0.redirect', { sameSite: 'lax' }).value = redirectUri ?? null;

        const url = new URL(`https://${config.public.auth0Domain}/authorize`);

        const parameters = new URLSearchParams({
            /* eslint-disable @typescript-eslint/naming-convention */
            client_id: config.public.auth0ClientId,
            scope: 'openid profile email offline_access',
            response_type: 'code',
            audience: 'https://api.masku.com',
            redirect_uri: config.public.auth0RedirectUri,
            store_id: config.public.storeId,
            /* eslint-enable @typescript-eslint/naming-convention */
        });

        url.search = parameters.toString();

        if (redirect) {
            await navigateTo(url.toString(), { external: true });

            return undefined;
        }

        return url.toString();
    };

    const refreshToken = async (): Promise<void> => {
        try {
            const preRefreshTokenCookie = useStatefulCookie('auth0.refresh_token');

            if (!preRefreshTokenCookie.value) {
                throw new Error('No refresh token');
            }

            const response = await $fetch<TokenResponse>(
                `https://${config.public.auth0Domain}/oauth/token`,
                {
                    method: 'POST',
                    body: {
                        /* eslint-disable @typescript-eslint/naming-convention */
                        client_id: config.public.auth0ClientId,
                        grant_type: 'refresh_token',
                        refresh_token: preRefreshTokenCookie.value,
                        /* eslint-enable @typescript-eslint/naming-convention */
                    },
                },
            );

            if (response.error) {
                throw new Error('Error requesting token');
            }

            const expires = new Date();

            if (response.access_token) {
                expires.setTime(Date.now() + ((response.expires_in || 86_400) * 1000));

                const tokenCookie = useStatefulCookie('auth0.token', {
                    path: '/',
                    expires,
                });

                tokenCookie.value = response.access_token;
            }

            if (response.refresh_token) {
                // time configured in auth0 application
                expires.setTime(Date.now() + 2_592_000_000);

                const refreshTokenCookie = useStatefulCookie('auth0.refresh_token', {
                    path: '/',
                    expires,
                });

                refreshTokenCookie.value = response.refresh_token;
            }
        } catch {
            useStatefulCookie('auth0.refresh_token').value = null;
        }
    };

    const getAccessToken = async (): Promise<string | undefined> => {
        if (!useStatefulCookie('auth0.refresh_token').value) {
            throw new Error('No refresh token');
        }

        const accessToken = useStatefulCookie('auth0.token');
        if (!accessToken.value || isJwtExpired(accessToken.value)) {
            await refreshToken();
        }

        return useStatefulCookie('auth0.token').value ?? undefined;
    };

    return {
        login,
        logout,
        isAuthenticated,
        getAccessToken,
    };
}
