<template>
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <div
        class="size-2.5 rounded-full border bg-[var(--bg-color)] md:size-3"
        :class="{
            'rainbow': isMulticolor,
            'border-none border-white': isWhite,
            'border-black': !isWhite,
        }"
        :style="{
            ...isMulticolor ? {} : {
                '--bg-color': finalColor
            },
        }"
    >
    </div>
</template>

<script setup lang="ts">
const properties = defineProps<{
    color: string | 'MULTI' | 'SILVER' | 'GOLD'
}>();

const isMulticolor = computed(() => properties.color === 'MULTI');

const finalColor = computed(() => {
    const map: Record<string, string> = {
        /* eslint-disable @typescript-eslint/naming-convention */
        MULTI: '#ffffff',
        SILVER: '#d7d6d6',
        GOLD: '#ffd700',
        /* eslint-enable @typescript-eslint/naming-convention */
    };

    return map[properties.color] ?? properties.color;
});

const isWhite = computed(() => {
    if (isMulticolor.value) {
        return true;
    }

    const strippedHex = finalColor.value.replace(/^#/, '');
    const r = Number.parseInt(strippedHex.slice(0, 2), 16);
    const g = Number.parseInt(strippedHex.slice(2, 4), 16);
    const b = Number.parseInt(strippedHex.slice(4, 6), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    return yiq < 215;
});
</script>

<style scoped>
.rainbow {
    background:
        linear-gradient(217deg, rgba(255, 0, 0, .8), rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(127deg, rgba(0, 255, 0, .8), rgba(0, 255, 0, 0) 70.71%),
        linear-gradient(336deg, rgba(0, 0, 255, .8), rgba(0, 0, 255, 0) 70.71%);
}
</style>
```
