import type { ISbStoriesParams } from 'storyblok-js-client';
import { type MaybeRef } from '@vueuse/core';

// NOTE: Also see useAsyncStoryblokStories

type ReffedParameters = {
    [K in keyof ISbStoriesParams]: MaybeRef<ISbStoriesParams[K]>
};

export function useStoryblokStoriesData(fetchKey: string, parameters: ReffedParameters) {
    const api = useStoryblokApi();

    const watchValues = Object.values(parameters).filter((value) => isRef(value));

    return useAsyncData(
        fetchKey,
        async () => {
            const concreteParameters: ISbStoriesParams = {};
            for (const [key, value] of Object.entries(parameters)) {
                concreteParameters[key as keyof ISbStoriesParams] = unref(value);
            }

            return api.getStories({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                ...concreteParameters,
                version: useRuntimeConfig().public.storyblokVersion === 'published' ? 'published' : 'draft',
            });
        },
        { watch: watchValues },
    );
}
