export function useSession() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const session = useState<Record<string, any> | null>('session');
    const { csrf } = useCsrf();

    const doRequest = async (method: 'GET' | 'PATCH', data?: Record<string, unknown>) => {
        const response = await useFetch('/api/session', {
            method,
            body: data,
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'csrf-token': csrf,
            },
        });

        return response.data.value;
    };

    const refresh = async () => {
        if (session.value) {
            return;
        }

        session.value = await doRequest('GET');
    };

    const update = async (data: Record<string, unknown>) => {
        session.value = await doRequest('PATCH', data);
    };

    return {
        refresh,
        update,
        session,
    };
}
