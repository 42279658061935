import type { ISbStoryData } from '@storyblok/js';

export function useStoryblokGlobalPage(path: string): ComputedRef<ISbStoryData | undefined> {
    const { data } = useStoryblokGetAll('storyblok-globals', 'cdn/stories', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        starts_with: 'global/',
    });

    return computed(() => data.value?.find((page) => page.slug === path));
}
