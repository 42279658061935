<template>
    <p class="flex flex-row items-center space-x-2 whitespace-nowrap"
        :style="variables"
        :class="{
            'text-[color:var(--text-color)]': textColor && !iconOnly,
            'bg-[color:var(--background-color)]': backgroundColor && !iconOnly,
            'rounded font-bold': !iconOnly,
            'px-2 py-1 text-xs md:px-3 md:text-sm': !iconOnly && !compact,
            'px-2 py-1 text-xs md:text-[13px]': !iconOnly && compact,
        }"
        :title="iconOnly ? badge.title : undefined">
        <v-icon v-if="badge.icon"
            :icon="icon"
            :class="{
                'text-lg md:text-3xl': icon !== 'inbank',
                'h-6 text-9xl': icon === 'inbank',
            }" />

        <span v-if="!iconOnly">
            <slot />
        </span>
    </p>
</template>

<script lang="ts" setup>
import type { ElevateProductBadge, Icon } from '~~/types';
import { type ViewProductDetailsQuery } from '~~/graphql';
import { kebabCase } from 'case-anything';

type DetailProduct = Extract<
    ViewProductDetailsQuery['product'],
    { __typename: 'SimpleProduct' }
>;

type ProductBadge = DetailProduct['productBadges'][0];

const properties = defineProps<{
    badge: ProductBadge | ElevateProductBadge
    iconOnly?: boolean
    compact?: boolean
}>();

const icon = computed<Icon | undefined>(() => (
    properties.badge.icon ? kebabCase(properties.badge.icon) as Icon : undefined
));

const backgroundColor = computed(() => properties.badge.backgroundColor);
const textColor = computed(() => properties.badge.textColor);

const variables = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    ...textColor ? {
        '--background-color': properties.badge.backgroundColor ?? undefined,
    } : {},
    ...backgroundColor ? {
        '--text-color': properties.badge.textColor ?? undefined,
    } : {},
    /* eslint-enable @typescript-eslint/naming-convention */
}));
</script>
