<template>
    <v-header-nav-page class="text-[color:var(--color)]"
        :style="{
            '--color': fontColor,
        }">
        <div class="flex flex-col space-y-md px-10 pt-md">
            <v-header-nav-link v-for="subMenu in subMenus"
                :key="subMenu.id"
                has-sub-nav
                @click="$emit('open', subMenu)">
                {{ subMenu.name }}
            </v-header-nav-link>

            <v-header-nav-link v-for="link in mappedSimpleLinks"
                :key="link.id"
                :to="link.url">
                {{ link.name }}
            </v-header-nav-link>
        </div>

        <div class="mt-xl flex flex-col space-y-md px-10">
            <v-header-small-nav-link v-for="item in iconItems"
                :key="item.id"
                :icon="item.icon"
                :to="item.url">
                {{ item.name }}
            </v-header-small-nav-link>
        </div>

        <!--        <div class="mt-auto px-10 pb-10">
            <a href="#"
                class="border border-white rounded py-2 px-3 inline-flex items-center">
                <v-icon icon="global"
                    class="mr-2 text-xl" />

                Suomi
            </a>
        </div>-->
    </v-header-nav-page>
</template>

<script lang="ts" setup>
import type { TreeItem, TreeItemWithIcon } from '~/server/api/navigation.get';

const properties = defineProps<{
    navigation: TreeItem[]
    fontColor?: string
}>();

defineEmits<{
    (event: 'open', item: TreeItem): void
}>();

const fontColor = computed<string | undefined>(() => (properties.fontColor === undefined
    ? '#FFF'
    : properties.fontColor));

const subMenus = computed(() => properties.navigation.filter((item) => item.type === 'category-root'));
const mappedSimpleLinks = computed(() => properties.navigation.filter((item) => item.type === 'normal'));
const iconItems = computed(() => (
    properties.navigation.filter((item): item is TreeItemWithIcon => item.type === 'icon')
));
</script>
