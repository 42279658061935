import { type ProductInventoryDataFragment } from '~~/graphql';

type Inventory = ProductInventoryDataFragment['inventory'];

const tKeys = {
    days: { product: 'delivery.days', cart: 'complete.delivery-days' },
    instant: { product: 'delivery.instant', cart: 'complete.instant' },
    weeks: { product: 'delivery.weeks', cart: 'complete.delivery-weeks' },
};

export function useDeliveryEstimateText() {
    const { t } = useI18n({
        useScope: 'global',
    });

    return {
        format: (inventory: Inventory, context: 'product' | 'cart') => {
            const days = inventory.deliveryEstimateDays ?? undefined;
            const inventoryState = inventory.state ?? undefined;
            const availableQuantity = inventory.availableQuantity ?? undefined;

            // Straight from warehouse -> always fast, estimate days not needed
            if (availableQuantity && availableQuantity > 0) {
                return t(tKeys.days[context], { amount: t('delivery.almost-instant-days') });
            }

            // Straightforward
            if (inventoryState === 'OUT_OF_STOCK') {
                return t('delivery.out-of-stock');
            }

            // Delivery days not defined - instant / fast
            if (days === undefined) {
                return t(tKeys.days[context], { amount: t('delivery.almost-instant-days') });
            }

            if (days <= 1) {
                return t(tKeys.instant[context]);
            }
            if (days === 2) {
                return t(tKeys.days[context], { amount: t('delivery.almost-instant-days') });
            }
            if (days <= 7) {
                return t('delivery.fast');
            }
            if (days <= 14) {
                return t(tKeys.weeks[context], { amount: '1-2' });
            }
            if (days <= 21) {
                return t(tKeys.weeks[context], { amount: '2-3' });
            }
            if (days <= 28) {
                return t(tKeys.weeks[context], { amount: '3-4' });
            }
            if (days <= 35) {
                return t(tKeys.weeks[context], { amount: '4-5' });
            }
            if (days <= 42) {
                return t(tKeys.weeks[context], { amount: '5-6' });
            }
            if (days <= 49) {
                return t(tKeys.weeks[context], { amount: '6-7' });
            }
            if (days <= 56) {
                return t(tKeys.weeks[context], { amount: '7-8' });
            }
            if (days <= 63) {
                return t(tKeys.weeks[context], { amount: '8-9' });
            }
            if (days <= 70) {
                return t(tKeys.weeks[context], { amount: '9-10' });
            }
            if (days <= 77) {
                return t(tKeys.weeks[context], { amount: '10-11' });
            }
            if (days <= 84) {
                return t(tKeys.weeks[context], { amount: '11-12' });
            }
            return t('delivery.over-12-weeks');
        },
    };
}
