<template>
    <footer v-if="contents"
        class="bg-black text-white">
        <!-- Row 1 -->
        <div v-if="contents.content.cta.length > 0"
            class="border border-x-0 border-t-0 border-solid border-white/20
            px-sm py-md lg:py-3xl">
            <div class="flex flex-col justify-center xl:mx-2xl xl:px-2xl">
                <!-- Call to action email field-->
                <div v-for="cta in contents.content.cta"
                    :key="cta._uid"
                    class="text-center">
                    <v-storyblok-component :blok="cta" />
                </div>
            </div>
        </div>

        <div class="w-full border border-x-0 border-t-0 border-solid border-white/20 px-sm md:grid md:grid-cols-12">
            <!-- Row 2 -->
            <div class="col-span-full flex flex-row justify-between py-md
                md:grid md:grid-cols-12 md:content-start md:items-start lg:py-2xl">
                <!-- Logo -->
                <div class="order-2 col-start-2 flex w-1/3 flex-col gap-6 md:order-none">
                    <nuxt-link to="/"
                        aria-label="Masku logo">
                        <v-icon icon="masku"
                            class="mt-6 text-[5rem] md:text-[6rem] lg:text-[8rem]"></v-icon>
                    </nuxt-link>

                    <!-- Small viewport icon -->
                    <v-icon v-if="isIconVisible"
                        :icon="icon"
                        class="block stroke-white text-[5rem] text-white md:text-[6rem] lg:hidden lg:text-[8rem]" />
                </div>

                <div class="order-1 col-span-full md:col-start-4 lg:order-none lg:w-full 2xl:w-2/3">
                    <div class="col-span-2 md:col-start-4 md:grid md:grid-cols-2 lg:grid-cols-5">
                        <div class="mb-md flex flex-col md:col-start-1
                            md:row-start-1 md:mx-lg lg:col-start-1 lg:row-start-1">
                            <p class="mb-sm text-lg font-bold">{{ contents.content.information_title }}</p>

                            <nuxt-link v-for="link in contents.content.information"
                                :key="link._uid"
                                :to="formatSbUrl(link.link?.cached_url)"
                                :aria-label="link.name"
                                class="pb-xs text-sm tracking-xl hover:underline">
                                {{ link.name }}
                            </nuxt-link>
                        </div>

                        <div class="mb-md flex flex-col md:col-start-2
                            md:row-start-1 md:mx-lg lg:col-start-2 lg:row-start-1">
                            <p class="mb-sm text-lg font-bold">{{ contents.content.buying_title }}</p>

                            <nuxt-link v-for="link in contents.content.buying"
                                :key="link._uid"
                                :to="formatSbUrl(link.link?.cached_url)"
                                :aria-label="link.name"
                                class="pb-xs text-sm tracking-xl hover:underline">
                                {{ link.name }}
                            </nuxt-link>
                        </div>

                        <div class="mb-md flex flex-col md:col-start-1
                            md:row-start-2 md:mx-lg lg:col-start-3 lg:row-start-1">
                            <p class="mb-sm text-lg font-bold">{{ contents.content.support_title }}</p>

                            <nuxt-link v-for="link in contents.content.support"
                                :key="link._uid"
                                :to="formatSbUrl(link.link?.cached_url)"
                                :aria-label="link.name"
                                class="pb-xs text-sm tracking-xl hover:underline">
                                {{ link.name }}
                            </nuxt-link>
                        </div>

                        <div class="mb-md flex flex-col md:col-start-2
                            md:row-start-2 md:mx-lg lg:col-start-4 lg:row-start-1">
                            <p class="mb-sm text-lg font-bold">{{ contents.content.services_title }}</p>

                            <nuxt-link v-for="link in contents.content.services"
                                :key="link._uid"
                                :to="formatSbUrl(link.link?.url)"
                                :aria-label="link.name"
                                class="pb-xs text-sm tracking-xl hover:underline">
                                {{ link.name }}
                            </nuxt-link>
                        </div>

                        <!-- Large viewport icon (has its own column) -->
                        <div class="hidden justify-center lg:flex">
                            <v-icon v-if="isIconVisible"
                                :icon="icon"
                                class="col-start-5 mt-6
                                stroke-white text-[5rem] text-white md:text-[6rem] lg:text-[8rem]" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Row 3 -->
        <div class="w-full px-sm py-md md:flex md:justify-center
            lg:grid lg:grid-cols-12">
            <div class="flex flex-row lg:col-start-4 lg:col-end-12">
                <!-- Payment methods -->
                <div class="col-start-4 mr-md flex w-1/2 flex-row md:mx-lg">
                    <div class="flex flex-col">
                        <p class="mb-sm text-lg font-bold">{{ contents.content.payment_title }}</p>

                        <div class="flex flex-row flex-wrap items-center gap-4">
                            <nuxt-link v-for="link in contents.content.payment"
                                :key="link._uid"
                                :to="formatSbUrl(link.link)"
                                :aria-label="link.name">
                                <nuxt-picture :src="link.icon.filename"
                                    loading="lazy"
                                    provider="storyblok"
                                    :alt="link.name"
                                    width="48"
                                    height="48"
                                    sizes="xs:120px sm:120px md:120px lg:120px xl:120px 2xl:120px"
                                    class="w-8 md:w-12" />
                            </nuxt-link>
                        </div>
                    </div>
                </div>

                <!-- Social media -->
                <div class="col-start-6 col-end-12 flex flex-col lg:mx-lg">
                    <p class="mb-sm text-lg font-bold">{{ contents.content.social_title }}</p>

                    <div class="flex flex-row items-center space-x-sm lg:space-x-lg">
                        <nuxt-link v-for="link in contents.content.social"
                            :key="link._uid"
                            :to="link.link ? link.link : '#'"
                            :aria-label="link.name"
                            target="_blank"
                            class="aspect-square size-6 object-contain md:size-6 lg:size-5">
                            <nuxt-picture :src="link.icon.filename"
                                loading="lazy"
                                provider="storyblok"
                                :alt="link.name"
                                width="48"
                                height="48"
                                :img-attrs="{
                                    class: 'h-full w-full object-contain'
                                }"
                                sizes="xs:120px sm:120px md:120px lg:120px xl:120px 2xl:120px"
                                class="aspect-square size-6 object-contain md:size-6 lg:size-5" />
                        </nuxt-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- Row 4 -->
        <div class="w-full border border-x-0 border-b-0 border-solid border-white/20 px-sm py-md">
            <div class="flex flex-col justify-between md:flex-row">
                <p class="md:order-none lg:order-1">
                    &copy; {{ t('masku') }}
                </p>

                <div class="flex flex-col md:order-1
                    md:flex-row lg:order-2">
                    <a v-for="link in contents.content.links"
                        :key="link._uid"
                        :href="formatSbUrl(link.link.cached_url)"
                        :aria-label="link.name"
                        class="py-xs text-sm tracking-xl text-white hover:underline md:px-sm md:py-0">
                        {{ link.name }}
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
const { t } = useI18n();

const contents = useStoryblokGlobalPage('footer');
const { format: formatSbUrl } = useSbUrl();

const isIconVisible = computed(() => contents.value?.content.show_additional_logo ?? false);
const icon = computed(() => contents.value?.content.additional_logo ?? null);
</script>
