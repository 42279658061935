<template>
    <div class="flex items-center space-x-sm">
        <nuxt-link :to="formatSbUrl(`/${$t('storyblok.product')}/${product.seo.slug}`)"
            class="relative flex size-20 items-center justify-center overflow-hidden rounded p-2">
            <div class="absolute inset-0 rounded bg-gray-500/5"></div>

            <nuxt-picture v-if="product.listingImage"
                :src="product.listingImage.url"
                height="112"
                loading="lazy"
                width="112"
                class="size-full"
                sizes="xs:100vw sm:112px md:224px lg:448px xl:896px 2xl:896px"
                :alt="product.name"
                :img-attrs="{
                    class: 'h-full w-full object-contain',
                }" />
        </nuxt-link>

        <div class="flex flex-1 items-center justify-between">
            <div class="flex flex-col space-y-xs">
                <nuxt-link :to="formatSbUrl(`/${$t('storyblok.product')}/${product.seo.slug}`)"
                    class="group">
                    <v-subtitle class="font-bold group-hover:underline">
                        {{ product.title || product.name }}
                    </v-subtitle>

                    <p v-if="product.specification"
                        class="line-clamp-2 leading-tight text-gray-500"
                        :title="product.specification">
                        {{ product.specification }}
                    </p>
                </nuxt-link>

                <div class="flex items-baseline space-x-2">
                    <template v-if="isClubPromotionActive && clubPrice">
                        <span class="font-prenton-cond text-xl">
                            {{ formatCurrency(clubPrice, { maximumFractionDigits: 2 }) }}
                        </span>

                        <del
                            v-if="product.pricing.price
                                && clubPrice !== Number(product.pricing.price.amount)"
                            class="text-xs text-gray-500 line-through">
                            {{ formatCurrency(product.pricing.price.amount, { maximumFractionDigits: 2 }) }}
                        </del>
                    </template>

                    <template v-else-if="product.pricing.price">
                        <span class="font-prenton-cond text-xl">
                            {{ formatCurrency(product.pricing.price.amount, { maximumFractionDigits: 2 }) }}
                        </span>

                        <del
                            v-if="product.pricing.originalPrice
                                && product.pricing.price.amount !== product.pricing.originalPrice?.amount"
                            class="text-xs text-gray-500 line-through">
                            {{ formatCurrency(product.pricing.originalPrice.amount, { maximumFractionDigits: 2 }) }}
                        </del>
                    </template>
                </div>
            </div>
        </div>

        <v-button theme="black"
            size="sm"
            class="uppercase"
            @click="addToCart">
            {{ $t('add') }}
        </v-button>
    </div>
</template>

<script setup lang="ts">
import { type ViewCartQuery } from '~/graphql';
import { useGtm } from '@gtm-support/vue-gtm';
import { useToast } from '~/store/toast';

// eslint-disable-next-line max-len
type RelatedProduct = Extract<Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>['products'][0]['product']['relatedProducts']['edges'][0]['node'], { __typename: 'SimpleProduct' }>;

const properties = defineProps<{
    product: RelatedProduct
}>();

const cart = useCart();
const gtm = useGtm();
const toast = useToast();
const { t } = useI18n();
const gtmValues = useGtmValues();
const { format: formatCurrency } = useCurrency();

const {
    isPromotionActive: isClubPromotionActive,
    clubPrice,
} = useClubPromotion(computed(() => properties.product), 'cart');

const { format: formatSbUrl } = useSbUrl();

const gaProduct = computed(() => properties.product
    && gtmValues.productToGa(properties.product, 1));

async function addToCart() {
    try {
        const cartResponse = await cart.addProduct(properties.product.id);
        const cartResponseData = cartResponse.data?.cartProductCreate;
        const cartResponseOk = cartResponseData?.__typename === 'CartProductCreatePayload';

        const cartData = cartResponseOk ? cartResponseData.cart : undefined;

        let gaCartProducts: ReturnType<typeof gtmValues.productToGa>[] = [];
        if (cartData) {
            gaCartProducts = cartData.products.map((cartProduct) => (
                gtmValues.productToGa(cartProduct.product, cartProduct.quantity)
            ));
        }

        gtm?.trackEvent({
            /* eslint-disable @typescript-eslint/naming-convention */
            event: 'add_to_cart',
            _clear: true,
            ecommerce: {
                currency: 'EUR',
                value: gaProduct.value.price,
                items: [gaProduct.value],
                ...gtmValues.cart.value,
            },
            /* eslint-enable @typescript-eslint/naming-convention */
        });

        gtm?.trackEvent({
            /* eslint-disable @typescript-eslint/naming-convention */
            event: 'va_add_to_cart',
            _clear: true,
            ecommerce: {
                currency: 'EUR',
                value: cartData ? Number.parseFloat(cartData.price.amount) : 0,
                items: gaCartProducts,
                ...gtmValues.cart.value,
            },
            /* eslint-enable @typescript-eslint/naming-convention */
        });
    } catch (error) {
        toast.add('warning', `${t('toast.cart-warning')}`);
        console.error(error);
    }
}
</script>
