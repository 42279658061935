import type { CookieRef, CookieOptions } from '#app';

type Options<T> = CookieOptions<T> & {
    readonly?: false | undefined
};

export function useStatefulCookie<T = string | null>(name: string, options?: Options<T>): CookieRef<T> {
    const cookie = useCookie<T>(name, options);
    const state = useState<T>(name, () => cookie.value);

    watch(state, (value) => {
        cookie.value = value;
    }, { deep: true });

    return state;
}
