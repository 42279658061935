import { useMutation } from '@urql/vue';
import { useGtm } from '@gtm-support/vue-gtm';
import {
    CartCreateDocument,
    type CartCreateMutation,
    type CartCreateMutationVariables,
    CartProductCreateDocument,
    type CartProductCreateMutation,
    type CartProductCreateMutationVariables,
    CartProductDeleteDocument,
    type CartProductDeleteMutation,
    type CartProductDeleteMutationVariables,
    CartProductUpdateDocument,
    type CartProductUpdateMutation,
    type CartProductUpdateMutationVariables,
    CartUpdatePromotionCodeDocument,
    type CartUpdatePromotionCodeMutation,
    type CartUpdatePromotionCodeMutationVariables,
} from '~~/graphql';

export function useCart(isWishlist = false) {
    const {
        public: {
            appUrl,
        },
    } = useRuntimeConfig();

    const cartCookie = useStatefulCookie(isWishlist ? 'wishlist' : 'cart');

    const gtm = useGtm();

    const {
        executeMutation: executeCreateCart,
    } = useMutation<CartCreateMutation, CartCreateMutationVariables>(
        CartCreateDocument,
    );

    const {
        executeMutation: executeCreateCartProduct,
    } = useMutation<CartProductCreateMutation, CartProductCreateMutationVariables>(
        CartProductCreateDocument,
    );

    const {
        executeMutation: executeDeleteCartProduct,
    } = useMutation<CartProductDeleteMutation, CartProductDeleteMutationVariables>(
        CartProductDeleteDocument,
    );

    const {
        executeMutation: executeCartUpdatePromotionCode,
    } = useMutation<CartUpdatePromotionCodeMutation, CartUpdatePromotionCodeMutationVariables>(
        CartUpdatePromotionCodeDocument,
    );

    const {
        executeMutation: executeUpdateCartProduct,
    } = useMutation<CartProductUpdateMutation, CartProductUpdateMutationVariables>(
        CartProductUpdateDocument,
    );

    const createCart = async (): Promise<string> => {
        const cartResponse = await executeCreateCart({
            input: {
                isWishlist,
            },
        });

        if (cartResponse.data?.cartCreate.__typename !== 'CartCreatePayload') {
            throw new Error('Could not create cart');
        }

        cartCookie.value = cartResponse.data.cartCreate.cart.id;

        return cartResponse.data.cartCreate.cart.id;
    };

    const addProduct = async (productId: string, quantity?: number) => {
        let cartId = cartCookie.value;

        if (!cartId) {
            cartId = await createCart();
        }

        return executeCreateCartProduct({
            input: {
                cartId,
                productId,
                quantity,
            },
        });
    };

    const removeProduct = async (cartProductId: string): Promise<void> => {
        const { data } = await executeDeleteCartProduct({
            id: cartProductId,
        });

        if (data?.cartProductDelete?.__typename !== 'CartProductDeletePayload') {
            return;
        }

        if (data.cartProductDelete.cart.products.length === 0) {
            gtm?.trackEvent({
                /* eslint-disable @typescript-eslint/naming-convention */
                event: 'empty_cart_manually',
                _clear: true,
                ecommerce: {
                    cart_ref: data.cartProductDelete.cart.id,
                },
                /* eslint-enable @typescript-eslint/naming-convention */
            });
        }
    };

    const updateProduct = async (cartProductId: string, quantity: number): Promise<void> => {
        if (quantity <= 0) {
            await removeProduct(cartProductId);

            return;
        }

        await executeUpdateCartProduct({
            id: cartProductId,
            quantity,
        });
    };

    const setPromotionCode = async (promotionCode: string): Promise<void> => {
        let cartId = cartCookie.value;

        if (!cartId) {
            cartId = await createCart();
        }

        const promoCodeUpdateResponse = await executeCartUpdatePromotionCode({
            id: cartId,
            promotionCode,
        });

        if (promoCodeUpdateResponse.data?.cartUpdatePromotionCode.__typename !== 'CartUpdatePayload') {
            throw new Error('Could not update the promotion code');
        }
    };

    // For completely resetting the cart upon natural events, i.e. when making a purchase.
    // If a manual "empty cart" button is needed, we should probably define a new method that clears the existing cart and doesn't just throw away the cookie - otherwise the cleared cart might look like an abandoned cart.
    const reset = () => {
        gtm?.trackEvent({
            /* eslint-disable @typescript-eslint/naming-convention */
            event: 'empty_cart',
            _clear: true,
            ecommerce: {
                cart_ref: cartCookie.value || '',
                cart_url: cartCookie.value ? `${appUrl}/cart/${cartCookie.value}/switch` : '',
            },
            /* eslint-enable @typescript-eslint/naming-convention */
        });

        cartCookie.value = null;
    };

    return {
        id: computed(() => cartCookie.value),
        addProduct,
        removeProduct,
        updateProduct,
        setPromotionCode,
        reset,
    };
}
