import type {
    Autocomplete,
    SearchPage,
    LandingPage,
    ProductPage,
} from '@apptus/esales-api';
import { useWindowSize } from '@vueuse/core';
import { nanoid } from 'nanoid';
import { useProfile } from '~~/store/profile';

export interface RecommendationListSettings {
    id: string
    algorithm?:
        | 'TOP_PRODUCTS'
        | 'PERSONAL'
        | 'ALTERNATIVES'
        | 'UPSELL'
        | 'CART'
        | 'NEWEST_PRODUCTS'
        | 'STYLE_WITH'
        | 'FAVORITES'
        | 'MORE_FROM_SERIES'
        | 'RECENTLY_VIEWED'
    label?: string
    limit?: number
    params?: unknown
    productRules?: string
    showMoreLink?: string
    visualization?: 'CAROUSEL' | 'GRID'
}

export function useElevate() {
    const { width } = useWindowSize();
    const profileStore = useProfile();
    const { categories } = useCookieFirst();
    const isTrackingAllowed = computed(() => categories.value?.advertising);
    const { refresh, session } = useSession();

    const rangeKeys = [
        'price',
        'width',
        'height',
        'depth',
        'weight',
    ];

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const convertFilters = (filters: Record<string, string[]>): Record<string, string> => {
        const result: Record<string, string> = {};

        for (const [key, values] of Object.entries(filters)) {
            if (values.length === 0) {
                // eslint-disable-next-line no-continue
                continue;
            }

            if (rangeKeys.includes(key)) {
                [result[`f.${key}.min`], result[`f.${key}.max`]] = values;
            } else {
                result[`f.${key}`] = values.join('|');
            }
        }

        return result;
    };

    const {
        public: {
            elevate: {
                clusterId,
                locale,
                market,
            },
        },
    } = useRuntimeConfig();

    const customAttributeList = [
        'id',
        'sku',
        'title',
        'type',
        'specification',
        'attributeBadges',
        'promotion',
        'delivery_estimate_days',
        'clubPrice',
        'productBadges',
        'variant.clubPrice',
    ];

    const baseUrl = `https://${clusterId}.api.esales.apptus.cloud/api/storefront/v3/`;

    async function doGet<T>(
        url: string,
        parameters: Record<string, unknown>,
    ) {
        await refresh();

        const sessionId = computed<string | undefined>(() => session.value?.id);
        const isPreview = computed(() => session.value?.elevatePreview === true);

        return useFetch<T>(baseUrl + url, {
            params: {
                customerKey: profileStore.profile?.id || sessionId.value || nanoid(),
                market,
                sessionKey: sessionId.value || nanoid(),
                locale,
                touchpoint: width.value >= 1024 ? 'DESKTOP' : 'MOBILE',
                viewId: isPreview.value === true ? 'preview' : undefined,
                ...parameters,
            },
        });
    }

    async function doPost<T>(
        url: string,
        data: Record<string, unknown>,
        parameters: Record<string, unknown> = {},
    ) {
        await refresh();

        const sessionId = computed<string | undefined>(() => session.value?.id);
        const isPreview = computed(() => session.value?.elevatePreview === true);

        return $fetch<T>(baseUrl + url, {
            method: 'POST',
            params: {
                customerKey: profileStore.profile?.id || sessionId.value || nanoid(),
                market,
                sessionKey: sessionId.value || nanoid(),
                locale,
                touchpoint: width.value >= 1024 ? 'DESKTOP' : 'MOBILE',
                viewId: isPreview.value === true ? 'preview' : undefined,
                ...parameters,
            },
            body: data,
        });
    }

    const getAutocompleteSuggestions = async (
        query: string,
    ) => doGet<Autocomplete>('queries/autocomplete', {
        q: query,
        notify: isTrackingAllowed.value,
        presentCustom: customAttributeList.join('|'),
    });

    const getSearchResults = async (
        query: string,
        origin?: string,
        skip?: number,
        limit?: number,
        sort?: string,
        filters: Record<string, string[]> = {},
    ) => doGet<SearchPage>('queries/search-page', {
        q: query,
        origin,
        skip,
        limit,
        sort,
        notify: isTrackingAllowed.value,
        presentCustom: customAttributeList.join('|'),
        ...convertFilters(filters),
    });

    const getLandingPage = async (
        reference: string,
        skip?: number,
        limit?: number,
        sort?: string,
        filters: Record<string, string[]> = {},
    ) => doGet<LandingPage>('queries/landing-page', {
        pageReference: reference,
        skip,
        limit,
        sort,
        notify: isTrackingAllowed.value,
        presentCustom: customAttributeList.join('|'),
        ...convertFilters(filters),
    });

    const getProductPage = async (
        productKey: string,
        recommendationLists: RecommendationListSettings[],
    ) => doPost<ProductPage>('queries/product-page', {
        recommendationLists,
    }, {
        productKey,
        notify: isTrackingAllowed.value,
        presentCustom: customAttributeList.join('|'),
    });

    const notifyClick = async (
        ticket: string,
    ) => {
        if (!isTrackingAllowed) {
            return;
        }

        await doPost<never>('notifications/click', {
            ticket,
        });
    };

    return {
        getAutocompleteSuggestions,
        getSearchResults,
        getLandingPage,
        getProductPage,
        notifyClick,
    };
}
