/* eslint-disable unicorn/consistent-function-scoping */
import type { ProductClubPriceDataFragment } from '~/graphql';

type Location =
    | 'product'
    | 'header'
    | 'cart'
    | 'cart-notification'
    | 'cart-related-products'
    | 'wishlist'
    | 'checkout';

export function useClubPromotion(
    product: Ref<ProductClubPriceDataFragment | undefined>,
    location: Location,
) {
    const {
        public: {
            clubPrice: {
                isEnabled,
            },
        },
    } = useRuntimeConfig();

    const toggleableLocations: Location[] = [
        'product',
        'header',
        'cart-notification',
        'cart-related-products',
        'wishlist',
    ];

    const isClubPriceEnabled = toggleableLocations.includes(location) ? isEnabled : true;

    const isPromotionActive = computed(() => (
        isClubPriceEnabled && product.value?.cartPromotions.some((promotion) => promotion.requiresLogin)
    ));

    const finalPrice = computed<number | undefined>(() => {
        if (!isClubPriceEnabled || !product.value) {
            return undefined;
        }

        const clubPromotion = product.value.cartPromotions.find((promotion) => promotion.requiresLogin);

        if (
            clubPromotion?.__typename === 'CartPromotionPercent'
            && clubPromotion.percentDiscount.percent
            && product.value.pricing.price?.amount
        ) {
            const price = Number(product.value.pricing.price.amount);
            const calculatedPrice = price - (price * (clubPromotion.percentDiscount.percent / 100));

            return Math.round(calculatedPrice * 100) / 100;
        }

        if (clubPromotion?.__typename === 'CartPromotionClubPrice' && product.value.pricing.clubPrice?.amount) {
            return Number(product.value.pricing.clubPrice.amount);
        }

        return undefined;
    });

    const discount = computed<number | undefined>(() => {
        if (!product.value || !product.value.pricing.price || !finalPrice.value) {
            return undefined;
        }

        return Number(product.value.pricing.price.amount) - finalPrice.value;
    });

    return {
        isPromotionActive,
        clubPrice: finalPrice,
        discount,
    };
}
