import {
    createClient,
    fetchExchange,
    mapExchange,
    ssrExchange,
} from '@urql/vue';
import { cacheExchange } from '@urql/exchange-graphcache';
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import { authExchange } from '@urql/exchange-auth';
import { persistedExchange } from '@urql/exchange-persisted';
import { retryExchange } from '@urql/exchange-retry';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { refocusExchange } from '@urql/exchange-refocus';
import { decodeJwt } from 'jose';
import { captureException } from '@sentry/vue';
import { getCacheKeys } from './urql/cache-keys';

/* eslint-disable @typescript-eslint/naming-convention */
interface TokenResponse {
    access_token?: string
    refresh_token?: string
    id_token?: string
    scope?: string
    expires_in?: number
    token_type?: string
    error?: string
}
/* eslint-enable @typescript-eslint/naming-convention */

export default defineNuxtPlugin(async (nuxtApp) => {
    const config = useRuntimeConfig();
    const { csrf } = useCsrf();
    const accessToken = useStatefulCookie('auth0.token');
    const refreshToken = useStatefulCookie('auth0.refresh_token');

    const ssr = ssrExchange({
        isClient: true,
    });

    // Restore SSR payload once app is created
    nuxtApp.hook('app:created', () => {
        if (nuxtApp.payload.data?.urql) {
            ssr.restoreData(nuxtApp.payload.data.urql);
        }
    });

    const cache = cacheExchange({
        // @ts-expect-error introspection is not typed
        schema: await import('~~/graphql/introspection.json'),
        storage: makeDefaultStorage(),
        keys: getCacheKeys(),
    });

    const client = createClient({
        url: `${config.public.appUrl}/graphql/`,
        requestPolicy: 'cache-first',
        fetchOptions: () => ({
            headers: {
                /* eslint-disable @typescript-eslint/naming-convention */
                'x-graphql-client-name': 'storefront',
                'x-graphql-client-version': config.public.build,
                ...csrf ? {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'csrf-token': csrf,
                } : {},
                /* eslint-enable @typescript-eslint/naming-convention */
            },
        }),
        exchanges: [
            authExchange(async ({ appendHeaders }) => ({
                addAuthToOperation(operation) {
                    if (!accessToken.value) {
                        return operation;
                    }

                    return appendHeaders(operation, {
                        authorization: `Bearer ${accessToken.value}`,
                    });
                },
                willAuthError() {
                    if (!accessToken.value) {
                        return false;
                    }

                    const { exp } = decodeJwt(accessToken.value);
                    const currentTime = Date.now() / 1000;

                    return !!exp && currentTime > exp;
                },
                didAuthError(error) {
                    return error.graphQLErrors.some((item) => item.extensions?.code === 'UNAUTHENTICATED');
                },
                async refreshAuth() {
                    try {
                        const response = await $fetch<TokenResponse>(
                            `https://${config.public.auth0Domain}/oauth/token`,
                            {
                                method: 'POST',
                                body: {
                                    /* eslint-disable @typescript-eslint/naming-convention */
                                    client_id: config.public.auth0ClientId,
                                    grant_type: 'refresh_token',
                                    refresh_token: refreshToken.value,
                                    /* eslint-enable @typescript-eslint/naming-convention */
                                },
                            },
                        );

                        if (response.error) {
                            throw new Error(response.error);
                        }

                        accessToken.value = response.access_token ?? null;
                        refreshToken.value = response.refresh_token ?? null;
                    } catch {
                        accessToken.value = null;
                        refreshToken.value = null;
                    }
                },
            })),
            requestPolicyExchange({
                ttl: 5 * 1000 * 60,
            }),
            retryExchange({
                maxNumberAttempts: 10,
            }),
            refocusExchange(),
            cache,
            ssr,
            persistedExchange({
                preferGetForPersistedQueries: true,
            }),
            mapExchange({
                onError(error, operation) {
                    error.graphQLErrors
                        .filter((graphQLError) => (
                            graphQLError.extensions.code !== 'PERSISTED_QUERY_NOT_FOUND'
                            && graphQLError.extensions.code !== 'UNAUTHENTICATED'
                        ))
                        .forEach((graphQLError) => {
                            captureException(graphQLError, {
                                extra: {
                                    locations: graphQLError.locations,
                                    path: graphQLError.path,
                                    extensions: graphQLError.extensions,
                                    variables: operation.variables,
                                    document: operation.query.loc?.source.body,
                                },
                            });
                        });
                },
            }),
            fetchExchange,
        ],
    });

    nuxtApp.provide('urql', client);
    nuxtApp.vueApp.provide('$urql', ref(client));
});
